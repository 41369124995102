import React, { useState } from 'react';

import {loginUser} from './api';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassowrd] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassowrd(event.target.value);
  };
  const clickGoogle = (event) => {
    event.preventDefault();
    window.location.href = '/api/google/login';
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    loginUser({user_email: email, user_password: password});
  };

  return (
    <div className='user-form-container'>
      <div className="login-page">
        <div className="form">
          <form className="login-form">
            <h1>Wellcome back</h1>
            <input type="text" placeholder="Username" value={email} onChange={handleEmailChange} />
            <input type="password" placeholder="Password" value={password} onChange={handlePasswordChange} />
            <button onClick={handleSubmit}>Continue</button>
            <p className="account-message">Not registered? <a href="/u/signup">Create an account</a></p>
            <button className="login-with" onClick={clickGoogle}>Sign in with Google</button>
            <button className="login-with">Sign in with Microsoft</button>
            <button className="login-with">Sign in with Apple</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
