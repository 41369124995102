// src/api.js
import axios from 'axios';
import store from '../store'; // Import the Redux store
import { setChats } from '../slice/chats';
import { setChatId, setMessages } from '../slice/messages';

// TODO: put this is .env file
const API_URL = 'https://tunebot.myecommerce.biz/api';

const requests = (method, url, data={}, state='chats') => {
  axios({
    method,
    url: `${API_URL}${url}`,
    data,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  }).then((response) => {
    if (state === 'chats') {
      if (response.data.chats){
        store.dispatch(setChats(response.data.chats));
      }
    }
    else if (state === 'messages') {
      if (response.data.messages){
        store.dispatch(setMessages(response.data.messages));
      }
    }
    else if (state === 'message') {
      getMessages(response.data.chat_id);
      // get url last part
      const myChatId = window.location.href.split('/').pop();
      if (myChatId !== response.data.chat_id) {
        store.dispatch(setChatId(response.data.chat_id));
        window.history.pushState(null, null, `/c/${response.data.chat_id}`);
        getChats();
      }
    }
    else if (state === 'login') {
      if (response.data.access_token) {
        localStorage.setItem('token', response.data.access_token);
        window.location.href = '/c/new';
      }
    }
    else if (state === 'signup') {
      window.location.href = '/u/login';
    }
    else if (state === 'bookmark') {
      getChats();
    }
  }).catch((error) => {
    console.error('Error:', error);
  });
};

const getChats = () => {
  requests('get', '/chats');
}

const getMessages = (chatId) => {
  requests('get', `/messages/${chatId}`, {}, 'messages');
};

const sendMessage = (data) => {
  requests('POST', '/messages', data, 'message');
};

const loginUser = (data) => {
  requests('POST', '/login', data, 'login');
}

const signUpUser = (data) => {
  requests('POST', '/signup', data, 'signup');
}

export { requests, getChats, getMessages, sendMessage, loginUser, signUpUser };
