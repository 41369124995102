import { createSlice } from '@reduxjs/toolkit'

export const messagesSlice = createSlice({
  name: 'messages',
  initialState: {
    messages: {},
    chatId: 'new',
  },
  reducers: {
    updateMessages: (state, action) => {
      state.messages[action.payload.message_id] = action.payload;
    },
    setMessages: (state, action) => {
      state.messages = {};
      action.payload.forEach(message => {
        state.messages[message.message_id] = message;
      });
    },
    setChatId: (state, action) => {
      state.chatId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateMessages, setMessages, setChatId } = messagesSlice.actions;

export default messagesSlice.reducer;
