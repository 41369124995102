import { createSlice } from '@reduxjs/toolkit';

export const chatsSlice = createSlice({
  name: 'chats',
  initialState: {
    chats: {},
  },
  reducers: {
    updateChat: (state, action) => {
      state.chats[action.payload.chat_id] = action.payload;
    },
    setChats: (state, action) => {
      state.chats = {};
      action.payload.forEach(chat => {
        state.chats[chat.chat_id] = chat;
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateChat, setChats } = chatsSlice.actions;

export default chatsSlice.reducer;
