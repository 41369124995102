// import components here
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';

import Message from './components/messages';
import History from './components/history';
import {getChats, getMessages, sendMessage} from './components/api';
import {setChatId, updateMessages, setMessages} from './slice/messages';

import './App.css';

function App() {
  const chats = useSelector((state) => state.chats.chats)
  const messages = useSelector((state) => state.messages.messages)
  const chatId = useSelector((state) => state.messages.chatId)
  const dispatch = useDispatch()

  const [inputValue, setInputValue] = useState('');

  const user_name = localStorage.getItem('name');
  // const user_picture = localStorage.getItem('picture');

  const lastX = 4;

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const clickNewChat = (event) => {
    event.preventDefault();
    window.history.pushState(null, null, `/c/new`);
    dispatch(setChatId('new'));
    dispatch(setMessages([]));
  };

  const getXMessages = () => {
    // get last X messages from messages list
    let myMes = Object.values(messages);
    let lastMes = []
    for (let i = myMes.length - 2; i > 0; i = i - 2) {
      lastMes.push(myMes[i]);
      lastMes.push(myMes[i-1]);
      if (myMes.length > lastX + i) {
        break;
      }
    }
    let lastMessage = [];
    lastMes.forEach((message) => {
      lastMessage.push({author: message.author_type, content: message.message_text});
    });
    return lastMessage;
  };

  const handleEnterPress = (event) => {
    if (event.keyCode === 13) {
      // prevent default behavior of enter key
      event.preventDefault();
      dispatch(updateMessages({ message_id: uuidv4(), message_text: inputValue.toString(), author_type: 'user' }));
      setInputValue('');
      sendMessage({
        user_prompt: inputValue,
        message_history: getXMessages(),
        chat_id: chatId,
      });
    }
  };

  useEffect(() => {
    // select input field by name
    document.querySelector('textarea[name="newMessage"]').focus();
    let myId = window.location.href.split('/').pop();
    if (myId === ''){
      window.history.pushState(null, null, `/c/new`);
      myId = 'new';
    }
    dispatch(setChatId(myId));
    // get all the chats from backend
    getChats();
    // get messages based on chatId from backend
    if (myId.length > 10){
      getMessages(myId);
    }
  }, []);

  return (
    <div className="App">
      <main>
        <div></div>
        <div className="conversation">
          {
            Object.values(messages).map((content, index) => {
              return <Message key={index} message={content.message_text} role={content.author_type} />;
            })
          }
        </div>
      </main>
      <aside>
        <div className="history">
          <ul className='new-chat-ul'>
            <li className='new-chat'>
              <a onClick={clickNewChat}>New Chat</a>
            </li>
          </ul>
          <ul className='chat-history'>
            {
              Object.values(chats).map((chat, index) => {
                return <History key={index} chat={chat} isActive={chatId===chat.chat_id ? true : false } />;
              })
            }
          </ul>
        </div>
      </aside>
      <footer>
        <div className="history">
          <p className="profile">
            {user_name}
          </p>
        </div>
        <div className="new-message">
          <textarea name="newMessage" value={inputValue} placeholder='Enter new message ...'
            onChange={handleInputChange}
            onKeyDown={handleEnterPress}
            className="newMessage" cols="30"></textarea>
          <input type="hidden" name="chatId" value="{{ chat_id }}"/>
        </div>
      </footer>
    </div>
  );
}

export default App;
