import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faCheck, faCancel } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { setChatId, setMessages } from '../slice/messages';
import { requests, getMessages, getChats } from './api';

const History = ({ chat, isActive }) => {
  // create state for chat bookmark
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();

  const editChatBookmark = (chat_bookmark) => {
    // send put fetch request to backend
    requests('PUT', `/chats/${chat.chat_id}`, { chat_bookmark }, 'bookmark');
  };

  const submitChatBookmark = (event) => {
    const aElement = document.getElementById(chat.chat_id);
    // send put fetch request to backend
    event.preventDefault();
    editChatBookmark(aElement.innerText);
    aElement.contentEditable = false;
    setIsEdit(false);
  };

  const makeBookmarkEditable = (event) => {
    // make <a> contentEditable="true"
    event.preventDefault();
    const aElement = document.getElementById(chat.chat_id);
    aElement.contentEditable = true;
    setIsEdit(true);
    // make <a> focused
    aElement.focus();
    // add event listener to <a> to send put fetch request to backend with new chat bookmark
    aElement.addEventListener('keydown', (eve) => {
      if (eve.key === 'Enter') {
        submitChatBookmark(eve);
      }
    });
  };

  const changeChatBookmark = (event) => {
    // send put fetch request to backend
    event.preventDefault();
    setMessages([]);
    window.history.pushState(null, null, `/c/${chat.chat_id}`);
    getMessages(chat.chat_id);
    // set chat_id in state
    dispatch(setChatId(chat.chat_id));
  };

  const cancelEdit = (event) => {
    event.preventDefault();
    getChats();
    const aElement = document.getElementById(chat.chat_id);
    aElement.contentEditable = false;
    setIsEdit(false);
  };

  const deleteChat = (event) => {
    // send delete fetch request to backend
    event.preventDefault();
    requests('DELETE', `/chats/${chat.chat_id}`, {}, 'bookmark');
    // check if chat_id in state is equal to chat_id of chat being deleted
    if (chat.chat_id === window.location.href.split('/').pop()) {
      // set messages to empty array
      dispatch(setMessages([]));
      // set chat_id in state to new chat_id
      dispatch(setChatId('new'));
      // set window location to /c/new
      window.history.pushState(null, null, '/c/new');
    }
  }

  return (
    <li className={isActive ? 'active' : ''}>
      <a
        id={chat.chat_id}
        onClick={(e) => changeChatBookmark(e)}
      >
        {chat.chat_bookmark}
      </a>
      <div className="icons">
        { isEdit ? <FontAwesomeIcon icon={faCheck} onClick={submitChatBookmark} /> : <FontAwesomeIcon icon={faEdit} onClick={makeBookmarkEditable} />}
        { isEdit ? <FontAwesomeIcon icon={faCancel} onClick={cancelEdit} /> : <FontAwesomeIcon icon={faTrash} onClick={deleteChat} />}
      </div>
    </li>
  );
};

export default History;
