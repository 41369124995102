import React from 'react';

const Message = ({ message, role }) => {
  return (
    <div className={`message ${role}`}>
      <p>{message}</p>
    </div>
  );
};

export default Message;
