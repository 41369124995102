import React, { useState } from 'react';
import { signUpUser } from './api';


const Signup = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassowrd] = useState('');

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassowrd(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    signUpUser({ user_fullname: name, user_email: email, user_password: password });
  };

  return (
    <div className='user-form-container'>
      <div className="login-page">
        <div className="form">
          <form className="register-form">
            <h1>Create your account</h1>
            <input type="text" placeholder="Name" value={name} onChange={handleNameChange} />
            <input type="text" placeholder="Email address" value={email} onChange={handleEmailChange} />
            <input type="password" placeholder="Password" value={password} onChange={handlePasswordChange} />
            <button onClick={handleSubmit} >Continue</button>
            <p className="account-message">Already registered? <a href="/u/login">Sign In</a></p>
            <button className="login-with">Sign up with Google</button>
            <button className="login-with">Sign up with Microsoft</button>
            <button className="login-with">Sign up with Apple</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
